var render = function render(){var _vm=this,_c=_vm._self._c;return _c('skeleton-loading',[_c('SkeletonCircle',{attrs:{"box-properties":{
      height: '180px',
      width: '180px',
    },"count":1}}),_c('div',{staticClass:"my-2"},[_c('skeleton-square',{attrs:{"box-properties":{
        height: '18px',
        width: '160px',
      },"count":1}})],1),_c('skeleton-square',{attrs:{"box-properties":{
      height: '16px',
      width: '60px',
    },"count":1}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }