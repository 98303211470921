var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-sm-12"},[(_vm.isLoadingHeader)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticStyle:{"width":"550px","margin-bottom":"20px"}},[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
                top: '10px',
                height: '25px',
              },"count":1}})],1)],1)])]):_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.getPublicClasses.total)?_c('h1',{staticClass:"allClasses__subtitles mobile-space m-0 p-0"},[_vm._v(" "+_vm._s(`${ _vm.getPublicClasses.total > 1 ? `${_vm.getPublicClasses.total} Clases` : '1 Clase' }`)+" ")]):_c('h1',{staticClass:"allClasses__subtitles mobile-space m-0 p-0"},[_vm._v(" Clases ")])]),(_vm.isLoading)?_c('div',{staticClass:"row"},_vm._l((3),function(index){return _c('skeleton-card-video',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4"})}),1):_c('div',[(_vm.getPublicClasses.items && _vm.getPublicClasses.items.length)?_c('div',{staticClass:"row show-in-desktop"},_vm._l((_vm.getPublicClasses.items),function(_class){return _c('card-class',{key:_class.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4",attrs:{"data":_class}})}),1):_vm._e(),(_vm.getPublicClasses.items && _vm.getPublicClasses.items.length)?_c('div',{staticClass:"col-12 view-home-tabs pl-0 mb-4 show-in-mobile"},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
            [545, 2],
            [825, 3],
            [1200, 4],
          ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getPublicClasses.items),function(_class){return _c('slide',{key:_class.id,staticClass:"d-flex flex-column",staticStyle:{"padding-right":"10px","flex-basis":"300px"}},[_c('card-class',{staticStyle:{"width":"300px"},attrs:{"data":_class}})],1)}),1)],1):_c('div',{staticClass:"text-center pt-4"},[_c('h3',[_vm._v(" No hay clases relacionadas con la busqueda \""+_vm._s(this.$route.query.q)+"\" ")])])]),(_vm.getPublicClasses.items && _vm.getPublicClasses.items.length)?_c('div',[(_vm.totalPages > 1)?_c('div',{staticClass:"pt-4 pb-2 d-flex justify-content-end border-top-0"},[_c('base-pagination',{staticClass:"pagination-style",attrs:{"value":_vm.page,"page-count":_vm.totalPages},on:{"input":_vm.paginationChange}})],1):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }