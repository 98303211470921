<template>
  <div class="row mt-5">
    <div class="col-sm-12">
      <div v-if="isLoadingHeader" class="row">
        <div class="col-md-12">
          <div style="width: 550px; margin-bottom: 20px">
            <skeleton-loading>
              <skeleton-square
                :box-properties="{
                  top: '10px',
                  height: '25px',
                }"
                :count="1"
              />
            </skeleton-loading>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center">
        <h1
          v-if="getPublicInstructorsList.total"
          class="allClasses__subtitles mobile-space m-0 p-0"
        >
          {{
            `${
              getPublicInstructorsList.total > 1
                ? `${getPublicInstructorsList.total} Instructores`
                : '1 Instructor'
            }`
          }}
        </h1>
        <h1 v-else class="allClasses__subtitles mobile-space m-0 p-0">
          Instructores
        </h1>
      </div>
      <div v-if="isLoading" class="row">
        <skeleton-instructor
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4"
        />
      </div>
      <div v-else>
        <div
          v-if="
            getPublicInstructorsList.items &&
            getPublicInstructorsList.items.length
          "
          class="row show-in-desktop"
        >
          <div
            v-for="{
              id,
              avatar,
              firstname,
              lastname,
              courses,
            } in getPublicInstructorsList.items"
            :key="id"
            class="col-12 col-sm-6 col-md-6 col-lg-3 col-xxl-3"
          >
            <router-link
              :to="`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`"
              style="text-decoration: none"
            >
              <div
                :style="{
                  backgroundImage: `url('${
                    avatar && avatar.url
                      ? avatar.url
                      : '@/assets/images/logo/default-img-instructors.png'
                  }')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: '50%',
                  height: '180px',
                  width: '180px',
                  maxHeight: '180px',
                  backgroundPosition: 'center',
                }"
              ></div>
              <h4 class="text-uppercase">{{ firstname }} {{ lastname }}</h4>
            </router-link>
            <p class="font-weight-500" style="color: #b7227e !important">
              {{ getCourseInstructorCourseVideo(courses) }} clases
            </p>
          </div>
        </div>

        <div
          v-if="
            getPublicInstructorsList.items &&
            getPublicInstructorsList.items.length
          "
          class="col-12 view-home-tabs pl-0 view-tabs-mobile mb-4 show-in-mobile"
        >
          <carousel
            :navigation-enabled="true"
            :per-page="1"
            :per-page-custom="[
              [545, 2],
              [825, 4],
              [1200, 4],
            ]"
            :scroll-per-page="true"
            :speed="500"
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for="{
                id,
                avatar,
                firstname,
                lastname,
                courses,
              } in getPublicInstructorsList.items"
              :key="id"
              class="d-flex flex-column"
              style="padding-right: 10px; flex-basis: 300px"
            >
              <div>
                <router-link
                  :to="`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`"
                  style="text-decoration: none"
                >
                  <div
                    :style="{
                      backgroundImage: `url('${
                        avatar && avatar.url
                          ? avatar.url
                          : '@/assets/images/logo/default-img-instructors.png'
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: '50%',
                      height: '180px',
                      width: '180px',
                      maxHeight: '180px',
                      backgroundPosition: 'center',
                    }"
                  ></div>
                  <h4 class="text-uppercase">{{ firstname }} {{ lastname }}</h4>
                </router-link>
                <p class="font-weight-500">
                  {{ getCourseInstructorCourseVideo(courses) }} clases
                </p>
              </div>
            </slide>
          </carousel>
        </div>
        <div v-else class="text-center pt-4">
          <h3>
            No hay intructores relacionados con la busqueda "{{
              this.$route.query.q
            }}"
          </h3>
        </div>
      </div>
      <div
        v-if="
          getPublicInstructorsList.items &&
          getPublicInstructorsList.items.length
        "
      >
        <div
          v-if="totalPages > 1"
          class="pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonSquare from '../../../../components/Skeleton/components/SkeletonSquare.vue';
import SkeletonLoading from '../../../../components/Skeleton/SkeletonLoading.vue';
import { mapGetters } from 'vuex';
import { FETCH_PUBLIC_INSTRUCTORS_LIST } from '../../../../store/actions/public';
import SkeletonInstructor from '../../../Components/skeletons/SkeletonInstructor.vue';
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'SearchInstructors',
  components: {
    SkeletonInstructor,
    SkeletonLoading,
    SkeletonSquare,
    Carousel,
    Slide,
  },
  data() {
    return {
      isLoadingHeader: true,
      isLoading: true,
      perPage: 4,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getPublicInstructorsList']),
    totalPages() {
      return Math.ceil(this.getPublicInstructorsList.total / this.perPage);
    },
  },
  watch: {
    $route: async function (to) {
      this.isLoading = true;
      this.isLoadingHeader = true;
      await this.getInstructors({
        per_page: this.perPage,
        page: this.page,
        order_by: 'ASC_firstname',
        search: to.query.q ?? '',
      });
    },
  },
  mounted() {
    this.getInstructors();
  },
  methods: {
    getInstructors(
      filter = {
        order_by: 'ASC_firstname',
        per_page: this.perPage,
        page: this.page,
        search: this.$route.query.q ?? '',
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_INSTRUCTORS_LIST, filter)
        .then(() => {
          this.isLoading = false;
          this.isLoadingHeader = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    getCourseInstructorCourseVideo(courses = []) {
      if (!courses.length) return 0;

      return courses.reduce((acc, { course }) => {
        return acc + course.course_videos.length;
      }, 0);
    },
    async paginationChange(page) {
      this.page = page;
      this.isLoading = true;
      await this.getInstructors({
        page,
        per_page: this.perPage,
        order_by: 'ASC_firstname',
        search: this.$route.query.q ?? '',
      });
    },
  },
};
</script>

<style scoped></style>
