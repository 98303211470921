<template>
  <div class="row mt-5">
    <div class="col-sm-12">
      <div v-if="isLoadingHeader" class="row">
        <div class="col-md-12">
          <div style="width: 550px; margin-bottom: 20px">
            <skeleton-loading>
              <skeleton-square
                :box-properties="{
                  top: '10px',
                  height: '25px',
                }"
                :count="1"
              />
            </skeleton-loading>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center">
        <h1
          v-if="getPublicClasses.total"
          class="allClasses__subtitles mobile-space m-0 p-0"
        >
          {{
            `${
              getPublicClasses.total > 1
                ? `${getPublicClasses.total} Clases`
                : '1 Clase'
            }`
          }}
        </h1>
        <h1 v-else class="allClasses__subtitles mobile-space m-0 p-0">
          Clases
        </h1>
      </div>
      <div v-if="isLoading" class="row">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4"
        />
      </div>
      <div v-else>
        <div
          v-if="getPublicClasses.items && getPublicClasses.items.length"
          class="row show-in-desktop"
        >
          <card-class
            v-for="_class in getPublicClasses.items"
            :key="_class.id"
            :data="_class"
            class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4"
          />
        </div>

        <div
          v-if="getPublicClasses.items && getPublicClasses.items.length"
          class="col-12 view-home-tabs pl-0 mb-4 show-in-mobile"
        >
          <carousel
            :navigation-enabled="true"
            :per-page="1"
            :per-page-custom="[
              [545, 2],
              [825, 3],
              [1200, 4],
            ]"
            :scroll-per-page="true"
            :speed="500"
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for="_class in getPublicClasses.items"
              :key="_class.id"
              class="d-flex flex-column"
              style="padding-right: 10px; flex-basis: 300px"
            >
              <card-class style="width: 300px" :data="_class" />
            </slide>
          </carousel>
        </div>
        <div v-else class="text-center pt-4">
          <h3>
            No hay clases relacionadas con la busqueda "{{
              this.$route.query.q
            }}"
          </h3>
        </div>
      </div>
      <div v-if="getPublicClasses.items && getPublicClasses.items.length">
        <div
          v-if="totalPages > 1"
          class="pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonCardVideo from '../../../Components/skeletons/SkeletonCardVideo.vue';
import { FETCH_PUBLIC_CLASSES } from '../../../../store/actions/public';
import { mapGetters } from 'vuex';
import CardClass from '../../Courses/components/CardClass.vue';
import { Carousel, Slide } from 'vue-carousel';
import SkeletonSquare from '../../../../components/Skeleton/components/SkeletonSquare.vue';
import SkeletonLoading from '../../../../components/Skeleton/SkeletonLoading.vue';

export default {
  name: 'SearchClasses',
  components: {
    SkeletonCardVideo,
    CardClass,
    Carousel,
    Slide,
    SkeletonSquare,
    SkeletonLoading,
  },
  data() {
    return {
      isLoadingHeader: true,
      isLoading: true,
      perPage: 3,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getPublicClasses']),
    totalPages() {
      return Math.ceil(this.getPublicClasses.total / this.perPage);
    },
  },
  watch: {
    $route: async function (to) {
      this.isLoading = true;
      this.isLoadingHeader = true;
      await this.fetchClassVideos({
        per_page: this.perPage,
        page: this.page,
        order_by: 'DESC',
        search: to.query.q ?? '',
      });
    },
  },
  async mounted() {
    await this.fetchClassVideos();
  },
  methods: {
    async fetchClassVideos(
      filter = {
        page: this.page,
        per_page: this.perPage,
        order_by: 'DESC',
        search: this.$route.query.q ?? '',
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_CLASSES, filter)
        .then(() => {
          this.isLoading = false;
          this.isLoadingHeader = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async paginationChange(page) {
      this.page = page;
      this.isLoading = true;
      await this.fetchClassVideos({
        page,
        per_page: this.perPage,
        order_by: 'DESC',
        search: this.$route.query.q ?? '',
      });
    },
  },
};
</script>

<style scoped>
.show-in-mobile {
  display: none;
}

@media (max-width: 600px) {
  .show-in-desktop {
    display: none;
  }

  .show-in-mobile {
    display: block;
    padding-top: 10px;
  }
}
</style>
