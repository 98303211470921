<template>
  <div>
    <search-classes />
    <search-courses />
    <search-instructors />
  </div>
</template>

<script>
import SearchClasses from './Class/components/SearchClasses.vue';
import SearchCourses from './Class/components/SearchCourses.vue';
import SearchInstructors from './Class/components/SearchInstructors.vue';

export default {
  name: 'ExploreSearch',
  metaInfo: {
    title: 'Explorar - Maquillate.com'
  },
  components: {
    SearchClasses,
    SearchCourses,
    SearchInstructors,
  },
};
</script>

<style scoped>
.input-search::placeholder {
  color: black !important;
}
</style>
