<template>
  <skeleton-loading>
    <SkeletonCircle
      :box-properties="{
        height: '180px',
        width: '180px',
      }"
      :count="1"
    />
    <div class="my-2">
      <skeleton-square
        :box-properties="{
          height: '18px',
          width: '160px',
        }"
        :count="1"
      />
    </div>
    <skeleton-square
      :box-properties="{
        height: '16px',
        width: '60px',
      }"
      :count="1"
    />
  </skeleton-loading>
</template>

<script>
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonCircle from '../../../components/Skeleton/components/SkeletonCircle.vue';
export default {
  name: 'SkeletonInstructor',
  components: {
    SkeletonLoading,
    SkeletonCircle,
    SkeletonSquare,
  },
};
</script>

<style scoped></style>
